@import '../styles/abstract.scss';

.errorPage {
	text-align: center;
	padding: 200px 0;

	@include media-below(xs) {
		padding: 50px;
	}

	h1 {
		font-size: 20px!important;
		line-height: 30px;
		font-weight: $font-semibold;
		margin: 0!important;
		display: block!important;
	}

	p > a {
		&:not(:last-child) {
			&:after {
				content: "•";
				display: inline-block;
				margin: 0 5px;
			}
		}
	}
}
